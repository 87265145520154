
import * as React from 'react'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { Box, Flex } from '@rebass/grid/emotion'
import { LocaleContext } from '../i18n'
import { FaFacebookSquare, FaYoutubeSquare } from 'react-icons/fa'
import { InlineLink } from '../components/Link'
import css from '@emotion/css'
import { colors, linkCSS } from '../styles/variables'
import { IconContext } from 'react-icons';

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
  }
}

const PageTemplate: React.SFC<PageTemplateProps> =
  ({ data, pageContext }) => {

    return <IndexLayout pageContext={pageContext}>
      <Page>
        <Flex
          alignItems="center"
          flexDirection="column"
        >
          <Box m="auto"
            mt={2}
            pl={4}
            pr={4}
          >
            <IconContext.Provider value={{ className: 'icons' }}>
              <LocaleContext.Consumer>{({ t }) =>
                <div>
                  <h2>{t('live.title')}</h2>
                  <ul css={css`
                    & li {
                      vertical-align: middle;
                    };
                    .icons {
                      ${linkCSS}; 
                    };`}>
                    <li>Ginczanka. {t('firebird.project')}
                      <InlineLink href="https://www.facebook.com/ginczanka.zar.ptak/"><FaFacebookSquare /></InlineLink>
                      <InlineLink href="https://youtu.be/_Dq4xis3bWw"><FaYoutubeSquare /></InlineLink>
                    </li>
                    <li>{t('national.theatre')}<InlineLink href="https://www.narodowy.pl/repertuar,spektakle,53,dowod_na_istnienie_drugiego.html">{t('repertoire')}</InlineLink>
                    </li>
                  </ul>

                </div>
              }</LocaleContext.Consumer>
            </IconContext.Provider>
          </Box>
        </Flex>
      </Page>
    </IndexLayout>
  }

export default PageTemplate